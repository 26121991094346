import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { LanguageContext } from "../components/language";
import { LayoutBootstrap } from "../components/layoutBootstrap";



const EventPage = ({data}) => {
    const {lang, setLang} = useContext(LanguageContext);

    return(
        <LayoutBootstrap>
            <Row className="oddcontainer"> 
              <Col>
              {/* large screens */}
              <div className="d-none d-sm-block" style={{paddingRight:'10rem', paddingLeft: '10rem'}}>
              
                <h1 className="bodytext" >News</h1>
                {data.allMdx.edges.filter(e => e.node.frontmatter.language === lang).map(element =>
                  <Row className="newsitem">
                      <Col  md={4} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <GatsbyImage image={getImage(element.node.frontmatter.featuredImage)} alt={"picture"}/>   
                      </Col>
                      <Col md={8}>
                        <Link to={"/news/"+ element.node.slug} className="bluelink">
                          <h4>{element.node.frontmatter.title}</h4>
                        </Link>
                        <p>{element.node.frontmatter.date}</p>
                        <p>{element.node.excerpt}</p>
                      </Col>
                  </Row>)}
                  </div>
                {/* small screens */}
              <div className="d-block d-sm-none">
              <h1 className="bodytext" >News</h1>
                {data.allMdx.edges.filter(e => e.node.frontmatter.language === lang).map(element =>
                  <Row className="newsitem">
                      <Col  md={4} style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <GatsbyImage image={getImage(element.node.frontmatter.featuredImage)} alt={"picture"}/>   
                      </Col>
                      <Col md={8}>
                        <Link to={"/news/"+ element.node.slug} className="bluelink">
                          <h4>{element.node.frontmatter.title}</h4>
                        </Link>
                        <p>{element.node.frontmatter.date}</p>
                        <p>{element.node.excerpt}</p>
                      </Col>
                  </Row>)}
              </div>

              </Col>
            </Row>
        </LayoutBootstrap>       
    )


}

export default EventPage

export const query = graphql`
query MyQuery2 {
  allMdx(sort: {fields: frontmatter___date, order: DESC}) {
    edges {
      node {
        frontmatter {
          title
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
          language
          date(formatString: "D MMMM YYYY")
          location
        }
        excerpt(pruneLength: 100)
        slug
      }
    }
  }
}`
